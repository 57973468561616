import { Box, LinearProgress, Stack, Step, StepLabel } from "@mui/material";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { RegisterDataButton } from "@pages/project-details/project-data-management/register-data-button";
import { useAppSelector } from "@store/store-helper";
import { isDevModeEnabledSelector } from "@store/app/app-selector";

interface Props {
  processStepState: StepState;
  state: WorkflowState;
  progress: number;
}

export function ProcessStep({
  processStepState,
  state,
  progress,
}: Props): JSX.Element {
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);

  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={processStepState === "active"}
          completed={processStepState === "done"}
        >
          <StepLabel StepIconComponent={DataManagementStepIcon} error={processStepState === "error"}>
            Process
          </StepLabel>
        </Step>
        {/* TODO: Use only DataManagementProgressBar once https://faro01.atlassian.net/browse/TF-1699 is done. */}
        {
          (progress !== -1) ?
          <DataManagementProgressBar progress={progress} /> :
          <LinearProgress
            variant="indeterminate"
            color="primary"
            sx={{
              "&.MuiLinearProgress-root": {
                flex: "1 1 auto",
                marginLeft: "10px",
                marginRight: "10px",
              },
            }}
          />
        }
      </Stack>
      { (state === "processed" || isDevModeEnabled) &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "28px",
        }}>
          <RegisterDataButton size="small" isDevMode={state !== "processed"} />
        </Box>
      }
    </Stack>
  );
}
