import { UploadedData } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { createSelector } from "@reduxjs/toolkit";
import {
  captureTreeEntityClusterPathSelector,
  captureTreeForMainRevisionSelector,
  isCaptureTreeScanEntityProcessingSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { RootState } from "@store/store-helper";
import { isScanEntity } from "@utils/capture-tree-utils";

/**
 * @returns All UploadedData entities.
 */
export const uploadedDataSelector: (state: RootState) => UploadedData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = captureTreeForMainRevisionSelector(state);
      const scanEntities = entities.filter(isScanEntity);

      return scanEntities.map((entity) => {
        return {
          ...entity,
          clusterPath: captureTreeEntityClusterPathSelector(entity.id)(state),
          isProcessing: isCaptureTreeScanEntityProcessingSelector(entity.id)(
            state
          ),
        };
      });
    }
  );

/**
 * @returns true if there is any UploadedData entity getting processed.
 */
export const isProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      return entities.some((entity) => entity.isProcessing);
    }
  );

/**
 * @returns true if all UploadedData entities, at least one, have been processed.
 */
export const isProcessedSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      // isProcessing also returns false if processing hasn't started yet. So also check the desired result.
      // There needs to be the initial ElsRaw and the created E57 point cloud.
      return 0 < entities.length && entities.every((entity) =>
        !entity.isProcessing && entity.pointClouds?.length && 2 <= entity.pointClouds.length
      );
    }
  );
