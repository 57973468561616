import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useAppParams } from "@router/router-helper";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { BaseTeamIdProps } from "@custom-types/sdb-company-types";

interface Props {
  /** Open team details page */
  openTeamDetails({ teamId, numberOfMembers }: OpenTeamDetails): void;
}

export interface OpenTeamDetails extends BaseTeamIdProps {
  /** The total number of members in a team */
  numberOfMembers?: number;
}

/** Custom hooks to have util functions for team */
export function useTeamUtils(): Props {
  const { trackEvent } = useTrackEvent();
  const { navigateToTeamDetails } = useAppNavigation();
  const { companyId } = useAppParams();

  function openTeamDetails({ teamId, numberOfMembers }: OpenTeamDetails): void {
    if (!companyId) {
      return;
    }

    trackEvent({
      name: TeamEvents.openTeam,
      props: {
        teamId,
        numberOfMembers: numberOfMembers ?? "",
      },
    });

    // Navigate to team details page
    navigateToTeamDetails({
      companyId,
      teamId,
    });
  }

  return { openTeamDetails };
}
