import { MemberTypes } from "@custom-types/member-types";
import { Box, Button, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useMembersUtils } from "@hooks/use-member-utils";
import { useToast } from "@hooks/use-toast";
import { isTeam } from "@utils/team-utils";

interface Props {
  /** The member to show the open profile button */
  member?: MemberTypes | SphereDashboardAPITypes.IUserAsManager | null;
}

/**
 * Component that shows the button to open the profile page of the user.
 */
export function UserMoreInfoMenuProfileButton({
  member,
}: Props): JSX.Element | null {
  const { openProfilePage, openViewTeamMembers } = useMembersUtils();
  const { showToast } = useToast();

  if (!member) {
    return null;
  }

  const isBelongsToTeam = isTeam(member);

  /**
   * Opens the profile page of the user or the team page members.
   * The permission validation is done in the canOpenProfilePage variable.
   */
  function openProfileOrTeamMembersClick(event: React.MouseEvent): void {
    // Since this button is normally shown inside a table row, we need to stop the propagation
    // otherwise it will count the click on the row.
    event.stopPropagation();

    if (isBelongsToTeam) {
      if (member?.id) {
        openViewTeamMembers({
          teamId: member.id,
        });
      } else {
        showToast({
          message: "User group profile page cannot be opened",
          type: "error",
          description:
            "The team ID is not available. Please reload the page to try again.",
        });
      }
    } else {
      openProfilePage({
        userIdentity: member?.identity,
        source: "user more info menu",
      });
    }
  }

  return (
    <Box
      component="div"
      sx={{
        padding: "10px",
      }}
    >
      <Button
        disableFocusRipple={true}
        disableRipple={true}
        sx={{
          color: sphereColors.gray750,
          fontSize: "14px",
          width: "100%",
          height: "37px",
          backgroundColor: sphereColors.pureWhite,
          border: "none",
          textAlign: "left",
          textTransform: "none",
          "&:hover": {
            backgroundColor: sphereColors.gray100,
          },
          "&:active": {
            backgroundColor: sphereColors.gray200,
          },
          "&:hover,:active": {
            color: sphereColors.blue500,
            cursor: "pointer",
          },
        }}
        onClick={openProfileOrTeamMembersClick}
      >
        <Grid container textAlign="start">
          {isBelongsToTeam ? "View members" : "Profile"}
        </Grid>
      </Button>
    </Box>
  );
}
