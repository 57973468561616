import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * Gets the initials of a team name to be shown in an avatar
 * E.g. "Sphere Developers" -> "SD"
 *
 * @param teamName The name of a team
 * @returns The initials to show inside an avatar.
 */
export function getTeamAvatarInitials(teamName?: string | null): string {
  if (!teamName) {
    return "";
  }
  const teamNameWords = teamName.split(" ");

  // If the team name has two or more words, return the first letter of the first two words.
  if (teamNameWords.length >= 2) {
    return `${teamNameWords[0][0]}${teamNameWords[1][0]}`.toUpperCase();
  }

  // If the team name has one word, return the first two letters of the word
  return teamName.slice(0, 2).toUpperCase();
}

/**
 * A list of regular expressions to identify special teams emails.
 *
 * These patterns are used to match specific email addresses that belong
 * to teams. If an email matches any of these patterns, it is considered
 * a team email.
 */
const teamEmailPatterns = [
  /^test\+ug-[a-f0-9-]+@holobuilder\.com$/,
  /^noreply\+ug-[a-f0-9]+@holobuilder\.com$/,
];

/**
 * Checks if the given email belongs to a special user group.
 *
 * This function validates the email against a set of predefined patterns.
 * If the email matches any of the patterns, it is considered an email belonging to a user group.
 *
 * @param email - The email address to be validated.
 * @returns true if the email matches any special user group patterns; otherwise, false.
 */
export function isTeam(
  member: MemberTypes | SphereDashboardAPITypes.IUserAsManager
): boolean {
  // Check if the member has a "kind" property and if it's a team
  if ("kind" in member) {
    return member.kind === "USER-GROUP";
  }

  // when the endpoint is working properly we should remove this line
  return teamEmailPatterns.some((pattern) => pattern.test(member.email));
}
