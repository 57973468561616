import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { selectProjectLoadingState } from "@faro-lotv/project-source";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { DataManagementTable } from "@pages/project-details/project-data-management/data-management-table";
import { StepState, WorkflowState, StepperIndices } from "@pages/project-details/project-data-management/data-management-types";
import { DataManagementTitle } from "@pages/project-details/project-data-management/data-mangement-title";
import {
  hasRegisterErrorSelector,
  isRegisteredSelector,
  isRegisteringSelector,
  registeredDataSelector,
} from "@pages/project-details/project-data-management/registered-data/registered-data-selectors";
import { publishedDataSelector } from "@pages/project-details/project-data-management/published-data/published-data-selectors";
import { ImportData } from "@pages/project-details/project-data-management/import-data/import-data";
import { ImportDataButton } from "@pages/project-details/project-data-management/import-data/import-data-button";
import {
  isProcessedSelector,
  isProcessingSelector,
  uploadedDataSelector,
} from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import {
  getProcessProgress,
  getRegisterProgress,
  getTableItemsFromEntities,
  getTableItemsFromTasks,
  getUploadedIdsMap,
  getUploadProgress,
} from "@pages/project-details/project-data-management/data-management-utils";
import {
  fetchingStatusAllRegistrationRevisionsSelector,
  fetchingStatusCaptureTreeForMainRevisionSelector,
  hasFetchedAllRegistrationRevisionsSelector,
  hasFetchedCaptureTreeForMainRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { currentUserSelector } from "@store/user/user-selector";
import { sphereColors } from "@styles/common-colors";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { FaroTextButton } from "@components/common/faro-text-button";
import { colorConst } from "@styles/common-colors";
import { DataManagementStepper } from "@pages/project-details/project-data-management/data-management-stepper/data-mangement-stepper";
import { BackgroundTaskState } from "@faro-lotv/service-wires";

/**
 * The main page for the Staging Area workflow, including the progress stepper and the scan table.
 * The code is unfortunately complex and "ugly" since the basic data elements weren't constructed to work
 * well with such a workflow and the user can also add additional data later, resetting the workflow while
 * older data still exists.
 */
export function DataManagementWorkflow({projectId}: BaseProjectIdProps): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  // ##### Upload tasks ##### //

  const uploadTasksAll = useAppSelector(uploadTasksSelector);
  // uploadTasksSelector also returns tasks for other projects and needs to be filtered.
  // That means we also can't use hasInProgressUploadTasksSelector since it also uses tasks of other projects.
  const uploadTasks = uploadTasksAll.filter((task) => task.context?.projectId === projectId);
  // Use the same code as in hasInProgressUploadTasksSelector here:
  let isUploading = uploadTasks.some((task) => [
    BackgroundTaskState.created,
    BackgroundTaskState.scheduled,
    BackgroundTaskState.started,
  ].includes(task.status));

  // ##### Uploaded data ##### //

  const uploadedEntities = useAppSelector(uploadedDataSelector);
  const isProcessing = useAppSelector(isProcessingSelector);
  const isProcessed = useAppSelector(isProcessedSelector);
  // TODO: The uploadedData entities currently don't contain info about an occurred error.
  // Detection and handling of errors will be part of https://faro01.atlassian.net/browse/TF-1699
  const hasProcessError: boolean = false;

  const timeUpload = useMemo(() => {
    let timeUpload = 0;
    for (const upload of uploadedEntities) {
      const time = new Date(upload.createdAt).getTime();
      if (timeUpload < time) {
        timeUpload = time;
      }
    }
    return timeUpload;
  }, [uploadedEntities]);

  const fetchingStatusUploaded = useAppSelector(fetchingStatusCaptureTreeForMainRevisionSelector);
  const hasFetchedCaptureTree = useAppSelector(hasFetchedCaptureTreeForMainRevisionSelector);
  const fetchingStatusIElements = useAppSelector(selectProjectLoadingState);

  // ##### Registered data ##### //

  const registeredEntities = useAppSelector(registeredDataSelector);
  const registeredEntity = registeredEntities.length ? registeredEntities[0] : undefined;
  const isRegistering = useAppSelector(isRegisteringSelector);
  const hasRegisterError = useAppSelector(hasRegisterErrorSelector);
  const registrationId = registeredEntity ? registeredEntity.id : undefined;
  const timeRegister = useMemo(() => {
    return registeredEntity ? (new Date(registeredEntity.modifiedAt)).getTime() : 0;
  }, [registeredEntity]);
  const isRegistered = useAppSelector(isRegisteredSelector) && timeUpload <= timeRegister;

  // fetchingStatusPrepared and hasFetchedAllRegistrationRevisions are also used for publishedData.
  const fetchingStatusRegistered = useAppSelector(fetchingStatusAllRegistrationRevisionsSelector);
  const hasFetchedAllRegistrationRevisions = useAppSelector(hasFetchedAllRegistrationRevisionsSelector);

  // ##### Published data ##### //

  const publishedEntities = useAppSelector(publishedDataSelector);
  const publishedEntity = publishedEntities.length ? publishedEntities[0] : undefined;
  // TODO: The act of publishing still needs to be implemented as part of the upcoming changes to the Staging Area:
  // https://faro01.atlassian.net/browse/TF-1693
  const isPublishing: boolean = false;
  const timePublish = useMemo(() => {
    return publishedEntity ? new Date(publishedEntity.modifiedAt).getTime() : 0;
  }, [publishedEntity]);
  const isPublished = !!publishedEntity && timeUpload <= timePublish && timeRegister <= timePublish;
  // Detection and handling of errors will be part of https://faro01.atlassian.net/browse/TF-1699
  const hasPublishError: boolean = false;

  // ##### Table items (Part 1) ##### //

  // We don't want to show table entries for (finished) upload tasks for which there's already a member in the
  // uploaded data array.
  const uploadedIdsMap: { [key: string]: boolean } = useMemo(() => {
    return getUploadedIdsMap(uploadedEntities);
  }, [uploadedEntities]);

  const { tableItemsFromTasks, isUploadingFromTasks, hasUploadErrorFromTasks } = useMemo(() => {
    return getTableItemsFromTasks(uploadTasks, uploadedIdsMap, currentUser);
  }, [uploadTasks, uploadedIdsMap, currentUser]);

  isUploading = isUploading || isUploadingFromTasks;
  const isUploaded = !isUploading && 0 < uploadedEntities.length;

  // ##### Determine the state ##### //

  // Determine the overall state.
  // The order should support uploading additional scans after later states have already been reached.
  // So the progress must be set to e.g. uploading although there already is e.g. published data.
  let state: WorkflowState = "start";
  let iActiveStep = StepperIndices.upload;

  if (isUploading) {
    state = "uploading";
    iActiveStep = StepperIndices.upload;
  } else if (hasUploadErrorFromTasks) {
    state = "uploadError";
    iActiveStep = StepperIndices.upload;
  } else if (isProcessing) {
    state = "processing";
    iActiveStep = StepperIndices.process;
  } else if (hasProcessError) {
    state = "processError";
    iActiveStep = StepperIndices.process;
  } else if (isRegistering) {
    state = "registering";
    iActiveStep = StepperIndices.register;
  } else if (hasRegisterError) {
    state = "registerError";
    iActiveStep = StepperIndices.register;
  } else if (isPublishing) {
    state = "publishing";
    iActiveStep = StepperIndices.publish;
  } else if (hasPublishError) {
    state = "publishError";
    iActiveStep = StepperIndices.publish;
  } else if (isPublished) {
    state = "published";
    iActiveStep = StepperIndices.publish;
  } else if (isRegistered) {
    state = "registered";
    iActiveStep = StepperIndices.publish;
  } else if (isProcessed) {
    state = "processed";
    iActiveStep = StepperIndices.register;
  } else if (isUploaded) {
    state = "uploaded";
    iActiveStep = StepperIndices.process;
  }

  // Determine the state of the stepper icons.
  let uploadStepState: StepState = "todo";
  if (StepperIndices.process <= iActiveStep) {
    uploadStepState = "done";
  } else if (state === "uploading") {
    uploadStepState = "active";
  } else if (state === "uploadError") {
    uploadStepState = "error";
  }

  let processStepState: StepState = "todo";
  if (StepperIndices.register <= iActiveStep) {
    processStepState = "done";
  } else if (state === "processing") {
    processStepState = "active";
  } else if (state === "processError") {
    processStepState = "error";
  }

  let registerStepState: StepState = "todo";
  if (StepperIndices.publish <= iActiveStep) {
    registerStepState = "done";
  } else if (state === "registering") {
    registerStepState = "active";
  } else if (state === "registerError") {
    registerStepState = "error";
  }

  let publishStepState: StepState = "todo";
  if (state === "published") {
    publishStepState = "done";
  } else if (state === "publishing") {
    publishStepState = "active";
  } else if (state === "publishError") {
    publishStepState = "error";
  }

  // Hide or disable the upload buttons while the data is processed in some way.
  const isUploadBtnDeactivated = state === "uploading" || state === "processing" ||
    state === "registering" || state === "publishing";
  const uploadBtnDisabledTooltip = isUploadBtnDeactivated ?
    "Uploading additional data is disabled during data processing" :
    undefined;

  // ##### Table items (Part 2) ##### //

  // We need to know if state is "published" before being able to determine the table items based on uploadedEntities.
  const tableItemsFromUploads = useMemo(() => {
    return getTableItemsFromEntities(state, uploadedEntities);
  }, [state, uploadedEntities]);

  const tableItems = useMemo(() => {
    return [ ...tableItemsFromTasks, ...tableItemsFromUploads ];
  }, [tableItemsFromTasks, tableItemsFromUploads]);

  // ##### Progress values ##### //

  const uploadProgress = useMemo(() => {
    return getUploadProgress(state, uploadTasks);
  }, [state, uploadTasks]);

  const processProgress = useMemo(() => {
    return getProcessProgress(state, uploadedEntities);
  }, [state, uploadedEntities]);

  const registerProgress = useMemo(() => {
    return getRegisterProgress(state, registeredEntity);
  }, [state, registeredEntity]);

  // ##### Empty page or error page ##### //

  const isFetchingForTheFirstTime = !hasFetchedCaptureTree &&
    !hasFetchedAllRegistrationRevisions &&
    (fetchingStatusUploaded === FetchingStatus.pending ||
     fetchingStatusRegistered === FetchingStatus.pending ||
     fetchingStatusIElements === "loading");

  const shouldShowEmptyPage = tableItems.length === 0 && !isFetchingForTheFirstTime;

  const hasFailedToFetchUploadedData = fetchingStatusUploaded === FetchingStatus.rejected ||
    fetchingStatusIElements === "failed";

  const hasFailedToFetchRegisteredData = fetchingStatusRegistered === FetchingStatus.rejected;

  const emptyPageTitle = hasFailedToFetchUploadedData || hasFailedToFetchRegisteredData ? "Error" : "No uploaded data";

  const emptyPageSubtitle = (hasFailedToFetchRegisteredData || hasFailedToFetchUploadedData) ?
    "Failed to fetch the uploaded data for this project! Please reload the page and try again." :
    "No uploaded data to show for this project.";

  const emptyPageIcon = hasFailedToFetchUploadedData || hasFailedToFetchRegisteredData ? FailedIcon : NoDataIcon;

  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const activityUrl = window.location.pathname.replace("/data-management", "/activity") + window.location.search;

  // ##### JSX element ##### //
  return (
    <div>
      {
        !shouldShowEmptyPage && (
          <div>
            <div style={{ float: "left" }}>
              <DataManagementTitle />
            </div>
            <div style={{ float: "right" }}>
              <ImportData
                projectId={projectId}
                uploadedIdsMap={uploadedIdsMap}
                isUploadDialogOpen={isUploadDialogOpen}
                setIsUploadDialogOpen={setIsUploadDialogOpen}
                isUploadBtnVisible={true}
                isUploadBtnDisabled={isUploadBtnDeactivated}
                uploadBtnDisabledTooltip={uploadBtnDisabledTooltip}
              />
            </div>
          </div>
        )
      }

      {isDevModeEnabled &&
        <FaroTextButton
          to={activityUrl}
          target="_blank"
          size="small"
          sx={{
            color: colorConst.devFeature,
          }}
          onClick={() => {
            // do nothing, we use "to" to open a new tab
          }}
        >
          Activity (dev)
        </FaroTextButton>
      }

      <Box
        data-testid="sa-workflow"
        sx={{
          marginTop: "14px",
          border: `1px solid ${sphereColors.gray200}`,
          borderRadius: 0,
          display: "inline-block",
          width: "100%",
        }}
      >
        {shouldShowEmptyPage ? (
          <>
            <EmptyPage
              title={emptyPageTitle} subtitle={emptyPageSubtitle} icon={emptyPageIcon}
              button={
                <ImportDataButton isDisabled={false} onClick={() => setIsUploadDialogOpen(true)} />
              }
            />
            {isUploadDialogOpen && (
              <ImportData
                projectId={projectId}
                uploadedIdsMap={uploadedIdsMap}
                isUploadDialogOpen={isUploadDialogOpen}
                setIsUploadDialogOpen={setIsUploadDialogOpen}
                isUploadBtnVisible={false}
                isUploadBtnDisabled={false}
              />
            )}
          </>
        ) : (
          <Box sx={{ background: "white" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: `1px solid ${sphereColors.gray200}`,
                paddingX: "30px",
                paddingTop: "30px",
                paddingBottom: "45px",
                fontWeight: "bold",
              }}
            >
              <div style={{ width: "100%" }}>
                <span style={{ marginRight: "100px", fontSize: "larger", float: "left" }}>
                  Blink scans
                 </span>

                <DataManagementStepper
                  state={state}
                  uploadStepState={uploadStepState}
                  processStepState={processStepState}
                  registerStepState={registerStepState}
                  publishStepState={publishStepState}
                  uploadProgress={uploadProgress}
                  processProgress={processProgress}
                  registerProgress={registerProgress}
                  setIsUploadDialogOpen={setIsUploadDialogOpen}
                  registrationId={registrationId}
                  projectId={projectId}
                  isUploadBtnVisible={!isUploadBtnDeactivated}
                />
              </div>
            </Box>
            <Box sx={{ paddingX: "30px", paddingBottom: "30px" }}>
              <DataManagementTable tableItems={tableItems} isFetchingForTheFirstTime={isFetchingForTheFirstTime} />
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}
