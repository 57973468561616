import { isNumber } from "lodash";
import { Euler, Quaternion } from "three";
import { BaseCaptureTreeEntity, ClusterEntity } from "@custom-types/capture-tree-types";
import { getTopLevelClusterEntities } from "@utils/capture-tree-utils";
import { EventProps } from "@faro-lotv/foreign-observers";
import { convertToDateString } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  CreateScanEntitiesParams,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { ElsScanFileUploadTaskContext, UploadedFile } from "@custom-types/file-upload-types";
import { DEFAULT_POSE_PARAM } from "@src/constants/capture-tree-constants";
import { isValidFile } from "@hooks/file-upload-utils";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";
import { ProjectApi } from "@api/project-api/project-api";
import { ReadLsDataV2Response, Scan } from "@api/stagingarea-api/stagingarea-api-types";
import { getScanByFilename } from "@api/stagingarea-api/stagingarea-api";

/**
 * Type for ref value to determine if we should auto-start the scan upload.
 * If the user adds or removes files, the value will be reset to `null` to abort the auto-start.
 */
export enum AutoStartUpload {
  /** We've sent the scan metadata files (LsDataV2) to the Staging Area API, and are waiting for the result. */
  waiting = "waiting",
  /** We've received the decoded scan metadata, and will auto-start the upload if all preconditions are met. */
  start = "start",
}

/**
 * List of allowed extensions for importing scan data.
 * "index-v2" & co don't have an extension, so we need to allow all extensions and filter later.
 */
export const ALLOWED_EXTENSIONS_ALL: string[] = [];

/** List of allowed extensions for the scan upload. */
export const ALLOWED_EXTENSIONS_GLS: string[] = ["gls"];

/** Maximum file size of each scan file: 20 GB. Arbitrary choice, but big enough for any Faro scan. */
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MAX_FILE_SIZE_IN_MB = 20 * FILE_SIZE_MULTIPLIER;

/** Els cluster name prefix */
const ELS_CLUSTER_NAME_PREFIX = "Blink Scans_";

/** Regular expression to validate the name of an ELS cluster. It should have the format "ELS Scans_{mm/dd/yyyy}" */
const ELS_CLUSTER_NAME_REGEX = new RegExp(
  `^${ELS_CLUSTER_NAME_PREFIX}\\d{2}\\/\\d{2}\\/\\d{4}$`
);

/** Regular expression to extract the UUID (usually UUIDv4) from "UUID.gls" or e.g. "UUID (2).gls". */
const CONTAINS_UUID_GLS_REGEX = /^.*([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}).*\.gls$/i;

/** @returns True if it's a *.gls file (ELS/Blink scan). */
export function isGLS(fileName: string): boolean {
  return fileName.toLowerCase().endsWith(".gls");
}

/** Extract the scan UUID from LsDataV2 or from the filename. */
export function getGlsUuid(fileName: string, lsDataV2: ReadLsDataV2Response | null): string | undefined {
  return getScanByFilename(fileName, lsDataV2)?.uuid ||
    fileName.match(CONTAINS_UUID_GLS_REGEX)?.[1]?.toLowerCase();
}

/** @returns True if the given GLS file may be uploaded. */
export function isValidGlsFile(file: File, lsDataV2: ReadLsDataV2Response | null): boolean {
  const isValid = isValidFile({
    file,
    allowedExtensions: ALLOWED_EXTENSIONS_GLS,
    maxFileSize: MAX_FILE_SIZE_IN_MB,
  }).isValid;

  // We only allow to upload scans whose UUID (as set by the scanner) is known.
  // Otherwise we can't check for duplicates later.
  return isValid && !!getGlsUuid(file.name, lsDataV2);
}

/** @returns The set of files (scans) whose UUIDs are contained in `uploadedIdsMap`. */
export function getScansAlreadyUploaded(
  files: File[],
  lsDataV2: ReadLsDataV2Response | null,
  uploadedIdsMap: { [key: string]: boolean }
): Set<File> {
  const filesFound = files.filter((file) => {
    const uuid = getGlsUuid(file.name, lsDataV2);
    return uuid && uploadedIdsMap[uuid];
  });
  return new Set(filesFound);
}

/** @returns True if it's the "index-v2" file from the LsDataV2 format. */
export function isIndexV2(fileName: string): boolean {
  return fileName.toLowerCase() === "index-v2";
}

/** @returns True if it's a file "ls-data/objects/[0-9a-f]" from the LsDataV2 format. */
export function isLsDataObject(file: File): boolean {
  // According to the FW team, there can be max. 16 files "ls-data/objects/[0-9a-f]",
  // and no multi-digit filenames.
  // If we have the relative path available, use it to better filter out irrelevant files,
  // since file.name = [0-9a-f] is quite generic.
  const isLsDataObject = file.webkitRelativePath ?
    file.webkitRelativePath.toLowerCase().includes("ls-data/objects/") :
    undefined;

  return isLsDataObject !== false && (/^[0-9a-f]$/i).test(file.name);
}

/** @returns Info about files being added (dragged or selected), for tracking. */
export function filesInfoForTracking(files: File[]): EventProps {
  return {
    filesGLS: files.filter((file) => isGLS(file.name)).length,
    filesIndexV2: files.filter((file) => isIndexV2(file.name)).length,
    filesOther: files.filter((file) => !isGLS(file.name) && !isIndexV2(file.name)).length,
  };
}

/** @returns Info about the data in the LsDataV2 package, for tracking. */
export function lsDataV2InfoForTracking(lsDataV2: ReadLsDataV2Response | null): EventProps {
  return {
    hasLsDataV2: !!lsDataV2,
    lsScans: lsDataV2?.scans?.length ?? 0,
    lsClusters: lsDataV2?.clusters?.length ?? 0,
    lsEdges: lsDataV2?.edges?.length ?? 0,
  };
}

/**
 * @param name string to validate
 * @returns Whether the passed string is a valid name for a ELS cluster
 */
export function isValidElsClusterName(name: string): boolean {
  return ELS_CLUSTER_NAME_REGEX.test(name);
}

/**
 * @returns a valid ELS cluster name of format "ELS Scans_{mm/dd/yyyy}"
 */
export function getElsClusterName(): string {
  const date = convertToDateString(new Date().toISOString(), "en-US");
  return `${ELS_CLUSTER_NAME_PREFIX}${date}`;
}

/**
 * @param entities The capture tree entities
 * @returns The first found "ELS" cluster entity or undefined if not found
 * A cluster is considered the "ELS" cluster if:
 * - its a top-level cluster and
 * - it has a valid name for an ELS cluster
 */
export function getElsClusterEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T> | undefined {
  const topLevelClusters = getTopLevelClusterEntities(entities);
  return topLevelClusters.find((cluster) =>
    isValidElsClusterName(cluster.name)
  );
}

interface GetScanEntitiesParamsProps {
  /** List of successfully uploaded scans to add */
  uploadedScans: UploadedFile[];

  /** ID of the revision cluster entity where the scans will be added */
  revisionClusterEntityId: string;

  /** Scan metadata, to map from filename (*.gls) to scan metadata from LsDataV2. */
  lsDataV2: ReadLsDataV2Response | null;
}

/**
 * @returns An array of params required to create capture tree scan entities from the passed uploaded scans
 */
export function getCreateScanEntitiesParams({
  uploadedScans,
  revisionClusterEntityId,
  lsDataV2,
}: GetScanEntitiesParamsProps): CreateScanEntitiesParams["requestBody"] {
  return uploadedScans.map((uploadedScan) => {
    // `scan` will be undefined in these cases, so we should allow it.
    //   - The user uploaded only the GLS files, without LsDataV2.
    //   - The user added an extra GLS file which is not in LsDataV2.
    // Knowing the scan UUID is still mandatory; it must be found in the *.gls file name then.
    const scan: Scan | undefined = getScanByFilename(uploadedScan.fileName, lsDataV2);

    const translation = scan?.trafo?.translation || [];
    const rotationAngles = scan?.trafo?.rotationAngles || [];
    const pose = {
      pos: DEFAULT_POSE_PARAM.pos,
      rot: DEFAULT_POSE_PARAM.rot,
    };
    if (isNumber(translation[0]) && isNumber(translation[1]) && isNumber(translation[2])) {
      pose.pos = {
        x: translation[0],
        y: translation[1],
        z: translation[2],
      };
    }
    if (isNumber(rotationAngles[0]) && isNumber(rotationAngles[1]) && isNumber(rotationAngles[2])) {
      // LsDataV2 does not yet properly specify its rotations. Our code here is at least consistent with the
      // Stream app, and produces correct results for usual cases (rotation around Z axis; XY almost 0°).
      // https://faro01.atlassian.net/wiki/spaces/FW/pages/4316332063/Unified+representation+of+scan+scanner+poses
      const euler = new Euler(rotationAngles[0], rotationAngles[1], rotationAngles[2], "XYZ");
      const quat = new Quaternion().setFromEuler(euler);
      pose.rot = {
        x: quat.x,
        y: quat.y,
        z: quat.z,
        w: quat.w,
      };
    }

    // Use the UUID from LsDataV2 if available, otherwise extract it from the filename.
    // Knowing the UUID is important to detect later if a scan was already uploaded, e.g. in case that the user
    // tries to upload the same project again with some additional scans.
    const externalId = scan?.uuid || getGlsUuid(uploadedScan.fileName, null);
    if (!externalId) {
      // Unexpected code path, because import-data > isConfirmDisabled > isValidGlsFile verifies that each scan has a UUID.
      throw new Error(`Logic error: We should only accept scans with a UUID: ${uploadedScan.fileName}`);
    }

    return {
      parentId: revisionClusterEntityId,
      type: CaptureTreeEntityType.elsScan,
      name: scan?.name || uploadedScan.fileName,
      pose,
      pointClouds: [
        {
          externalId,
          type: CaptureTreePointCloudType.elsRaw,
          uri: uploadedScan.downloadUrl,
          md5Hash: uploadedScan.md5,
          fileSize: uploadedScan.fileSize,
          fileName: uploadedScan.fileName,
        },
      ],
    };
  });
}

/**
 * Handles the logic after the upload of ELS scan succeeded:
 * - Adds (creates) successful uploads to the specified cluster of the project revision.
 * - Updates revision to "registered" status and merges it to the main revision.
 */
export async function addScansToRevisionAndMergeToMainHelper(
  successfulUploads: UploadedFile[],
  projectApiClient: ProjectApi,
  context: ElsScanFileUploadTaskContext
): Promise<void> {
  const scanEntitiesParams = getCreateScanEntitiesParams({
    uploadedScans: successfulUploads,
    revisionClusterEntityId: context.revisionClusterEntityId,
    lsDataV2: context.lsDataV2,
  });

  await projectApiClient.createScanEntitiesForRegistrationRevision({
    registrationRevisionId: context.registrationRevisionId,
    requestBody: scanEntitiesParams,
  });

  await projectApiClient.updateRegistrationRevision({
    registrationRevisionId: context.registrationRevisionId,
    state: RegistrationState.registered,
  });

  await projectApiClient.applyRegistrationRevisionToMain(
    context.registrationRevisionId
  );
}
