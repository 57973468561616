import { FaroIconButton } from "@components/common/faro-icon-button";
import { ContentCopy } from "@mui/icons-material";
import { Box, Link, Popover, SxProps } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/foreign-observers";
import {
  addTransparency,
  copyToClipboard,
  getSvgColoredIconCss,
} from "@utils/ui-utils";
import { CSSProperties, useState } from "react";

/** CSS style to apply when the button is hovered. */
const hoverStyle: CSSProperties = {
  backgroundColor: addTransparency({
    color: sphereColors.gray500,
    alpha: EDecimalToHex.forty,
  }),
  ...getSvgColoredIconCss(sphereColors.gray100),
};

/** CSS style to apply when the button is clicked. */
const clickStyle: CSSProperties = {
  backgroundColor: addTransparency({
    color: sphereColors.gray500,
    alpha: EDecimalToHex.seventy,
  }),
  ...getSvgColoredIconCss(sphereColors.gray100),
};

interface Props {
  /** Text to show in the link component that opens the popover */
  label?: string;

  /** Content of the popover */
  message: string;

  /** Optional style to pass to parent component */
  sx?: SxProps;
}

/** Notification popover that shows task message */
export function SphereNotificationPopover({
  label = "Details",
  message,
  sx,
}: Props): JSX.Element {
  /** HTML element used to set the position of the menu. */
  const [notificationAnchorElement, setNotificationAnchorElement] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(notificationAnchorElement);

  /** Triggered when the message text is clicked to open the notification */
  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    setNotificationAnchorElement(event.currentTarget);
  }

  /** Closes the notification */
  function handleClose(): void {
    setNotificationAnchorElement(null);
  }

  /** Copies the message string to the clipboard */
  function copyDetails(): void {
    copyToClipboard(message);
  }

  return (
    <>
      <Link
        onClick={handleClick}
        sx={{
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: 600,
          color: sphereColors.blue500,
          textDecorationColor: sphereColors.blue500,
          cursor: "pointer",
          ...withEllipsis,
          ...sx,
        }}
      >
        {label}
      </Link>
      <Popover
        anchorEl={notificationAnchorElement}
        id="task-status-notification"
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableAutoFocus
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 600,
              color: sphereColors.gray100,
              backgroundColor: sphereColors.gray950,
              padding: "6px 12px",
              boxShadow: "none",
              width: "284px",
            },
          },
        }}
      >
        <Box component="div" className={NO_TRANSLATE_CLASS}>
          {message}
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <FaroIconButton
            component={ContentCopy}
            onClick={copyDetails}
            iconSize="14px"
            buttonSize="20px"
            color={sphereColors.gray100}
            iconButtonProps={{
              sx: {
                "&:hover": {
                  ...hoverStyle,
                },
                "&:active": {
                  ...clickStyle,
                },
              },
            }}
          />
        </Box>
      </Popover>
    </>
  );
}
