import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { MemberTypes } from "@custom-types/member-types";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { isTeam } from "@utils/team-utils";

/** All the possible headers for members table */
export enum MemberHeaders {
  /** User avatar */
  avatar = "Avatar",

  /** User full name */
  user = "User",

  /** User full name or user group name */
  name = "Name",

  /** User email */
  email = "Email",

  /** User status */
  status = "Status",

  /** User role */
  role = "Role",

  /** Date in which a member joined a group */
  joinedDate = "Joined date",

  /** Any other possible options */
  options = "Options",
}

/** Returns the specific role column header according to the table context type */
export function getRoleHeader(
  tableSubject: MemberTableSubject | undefined
): string {
  switch (tableSubject) {
    case "workspace":
      return "Workspace Role";

    case "project":
      return "Project Role";

    default:
      return "Role";
  }
}

/** The display name to show representing memberTableSubject */
export function getMemberTableSubjectDisplayName(
  memberTableSubject: MemberTableSubject
): string {
  switch (memberTableSubject) {
    case "project":
    case "group":
    case "workspace":
    case "snapshot":
      return memberTableSubject;

    case "team":
      return TEAM_DISPLAY_NAME;
  }
}

/**
 * Compares two email addresses to determine their group status.
 *
 * This function checks if the given email addresses belong to a team
 * based on predefined patterns. It returns a value indicating the relative
 * order of the emails for sorting purposes:
 * - Returns 1 if the first email belongs to a user group and the second does not.
 * - Returns -1 if the second email belongs to a user group and the first does not.
 * - Returns 0 if both emails either belong to the same group status (both group or non-group).
 *
 * @param member1 - The first member to compare.
 * @param member2 - The second member to compare.
 */
export function compareTeamEmails(
  member1: MemberTypes,
  member2: MemberTypes
): number {
  const isTeam1 = isTeam(member1);
  const isTeam2 = isTeam(member2);

  if (isTeam1 && !isTeam2) {
    return 1;
  }
  if (!isTeam1 && isTeam2) {
    return -1;
  }
  return 0;
}
