import { Box, Stack, Step, StepLabel } from "@mui/material";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { StepState } from "@pages/project-details/project-data-management/data-management-types";
import { OpenViewerButton } from "@pages/project-details/project-data-management/open-viewer-button";

interface Props extends BaseProjectIdProps {
  publishStepState: StepState;
}

export function PublishStep({
  publishStepState,
  projectId,
}: Props): JSX.Element {
  return (
    <Stack direction="column" spacing={0}>
      <Step
        active={publishStepState === "active"}
        completed={publishStepState === "done"}
      >
        <StepLabel StepIconComponent={DataManagementStepIcon} error={publishStepState === "error"}>
          Publish
        </StepLabel>
      </Step>
      { (publishStepState === "done") &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "18px",
        }}>
          <OpenViewerButton projectId={projectId} />
        </Box>
      }
    </Stack>
  );
}
