import { Box, Stack, Step, StepLabel } from "@mui/material";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { InspectAndPublishButton } from "@pages/project-details/project-data-management/inspect-and-publish-button";
import { useAppSelector } from "@store/store-helper";
import { isDevModeEnabledSelector } from "@store/app/app-selector";

interface Props extends BaseProjectIdProps {
  registerStepState: StepState;
  registrationId?: string;
  state: WorkflowState;
  progress: number;
}

export function RegisterStep({
  registerStepState,
  projectId,
  registrationId,
  state,
  progress,
}: Props): JSX.Element {
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);

  // See the comment in data-management-step-icon.tsx why we need custom code to display the correct icon.
  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={registerStepState === "active"}
          completed={registerStepState === "done"}
        >
          <StepLabel StepIconComponent={DataManagementStepIcon} error={registerStepState === "error"}>
            Register
          </StepLabel>
        </Step>
        <DataManagementProgressBar progress={progress} />
      </Stack>
      { (!!registrationId && (registerStepState === "done" || isDevModeEnabled)) &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "24px",
        }}>
          <InspectAndPublishButton
            projectId={projectId}
            registrationId={registrationId}
            isPublished={state === "published"}
            isDevMode={registerStepState !== "done"}
          />
        </Box>
      }
    </Stack>
  );
}
