import InfoSvg from "@assets/icons/new/info-circle_24px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";

export function DataManagementTitle(): JSX.Element {
  return (
    <div style={{ fontSize: "24px" }}>
      Data
      <SphereTooltip
        enterDelay={0}
        title={
          <div>
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>
              Blink Data
            </div>
            Currently, only .gls files are supported in this version.
          </div>
        }
        tooltipProps={{ sx: { float: "right" } }}
      >
        {/* No click handler, we only want the hover effect. */}
        <FaroIconButton
          component={InfoSvg}
          iconButtonProps={{ sx: { marginLeft: "2px" } }}
        />
      </SphereTooltip>
    </div>
  );
}
