import { SvgIcon } from "@mui/material";
import ArrowLeftIcon from "@assets/icons/new/arrow-left-2_16px.svg?react";
import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useAppParams } from "@router/router-helper";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { sphereColors } from "@styles/common-colors";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { withEllipsis } from "@styles/common-styles";

/** Button in the header of the Staging Area to go back to the project overview. */
export function ProjectOverviewButton(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { navigateToProjectDetail } = useAppNavigation();
  const { companyId } = useAppParams();
  const project = useAppSelector(selectedProjectSelector);

  /** Closes the Staging Area and shows the project details page again. */
  function showProjectOverview(): void {
    if (!companyId || !project) {
      return;
    }
    navigateToProjectDetail({ companyId, projectId: project.id });
    trackEvent({
      name: DataManagementEvents.openProjectOverview,
      props: { projectId: project.id },
    });
  }

  return (
    <SphereTooltip title="Back to Project Overview">
      <FaroTextButton
        sx={{
          height: "90px",
          marginLeft: "-24px",
          paddingLeft: "24px",
          paddingRight: "24px",
          borderRadius: "0px",
          color: sphereColors.gray800,
          borderRight: `1px solid ${sphereColors.gray200}`,
        }}
        onClick={() => showProjectOverview()}
        dataTestId="sa-project-overview-button"
      >
        <SvgIcon inheritViewBox component={ArrowLeftIcon} sx={{ width: "16px" }} />
        <span style={{ marginLeft: "10px", fontSize: "16px", ...withEllipsis }}>
          Project Overview
        </span>
      </FaroTextButton>
    </SphereTooltip>
  );
}
