import { SphereAvatar } from "@components/header/sphere-avatar";
import { MemberTypes } from "@custom-types/member-types";
import { Box, PopperProps, Tooltip } from "@mui/material";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { getPendingUserIcon, getUserInitials } from "@utils/user-utils";
import {
  MENU_WIDTH,
  UserMoreInfoMenu,
} from "@components/user-more-info-menu/user-more-info-menu";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import Team from "@assets/icons/new/team.svg?react";
import { sphereColors } from "@styles/common-colors";
import { isTeam } from "@utils/team-utils";

interface Props {
  /** The member to show the avatar with its respective menu */
  member?: MemberTypes | SphereDashboardAPITypes.IUserAsManager | null;

  /** If true removes the white rim between the avatar and the blue wrapper when shouldUseSimplifiedAvatar is false */
  shouldHideWhiteRim?: boolean;
}

/** Time to wait between hover and open the dialog */
const enterDelay = 200;

/** Popper props to be used in the tooltip */
export const tooltipPopperProps: Partial<PopperProps> = {
  sx: {
    "& .MuiTooltip-tooltip": {
      width: MENU_WIDTH,
      backgroundColor: "white",
      padding: "0px",
      margin: "0px !important",
    },
  },
};

/**
 * Shows the user avatar and when hovered shows a menu with more info about the user.
 */
export function UserAvatarWithMenu({
  member,
  shouldHideWhiteRim = true,
}: Props): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const isCurrentUser = member?.identity === currentUser?.identity;

  if (!member) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- we need as empty component.
    return <></>;
  }

  return (
    <Tooltip
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      PopperProps={tooltipPopperProps}
      title={<UserMoreInfoMenu member={member} />}
    >
      <Box
        component="div"
        sx={{
          cursor: "pointer",
        }}
      >
        {isTeam(member) ? (
          <SphereAvatar
            icon={<Team />}
            size="small"
            shouldHideWhiteRim={true}
            backgroundColor={sphereColors.gray700}
          />
        ) : (
          <SphereAvatar
            initials={getUserInitials(member)}
            src={member?.thumbnailUrl}
            icon={getPendingUserIcon(member)}
            shouldUseSimplifiedAvatar={!isCurrentUser}
            shouldHideWhiteRim={shouldHideWhiteRim}
            size="small"
          />
        )}
      </Box>
    </Tooltip>
  );
}
