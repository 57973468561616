import { SphereAvatar } from "@components/header/sphere-avatar";
import { MemberTypes } from "@custom-types/member-types";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import {
  getPendingUserIcon,
  getPrettyName,
  getUserInitials,
  isMemberActive,
} from "@utils/user-utils";
import { getMailToLink } from "@utils/email-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import Team from "@assets/icons/new/team.svg?react";
import { isTeam } from "@utils/team-utils";

interface Props {
  /** The member to show the details in the title */
  member?: MemberTypes | SphereDashboardAPITypes.IUserAsManager | null;

  /** If the member is the current user */
  isCurrentUser: boolean;
}

/** Width in pixels for the name and email wrapper, needed to show ellipsis */
const NAME_EMAIL_WRAPPER_WIDTH = "165px";

/**
 * Component that shows the title of the menu when the user hovers over a member to see a quick
 * overlook about the user and open the profile page.
 * Includes the avatar, name and email.
 */
export function UserMoreInfoMenuTitle({
  member,
  isCurrentUser,
}: Props): JSX.Element {
  if (!member) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- we need as empty component.
    return <></>;
  }

  return (
    <Box
      component="div"
      sx={{
        height: "83px",
        width: "100%",
        backgroundColor: sphereColors.gray50,
        display: "flex",
        alignItems: "center",
        padding: "11px",
      }}
    >
      {/* Wrapper for avatar */}
      <Box
        component="div"
        sx={{
          marginRight: "16px",
        }}
      >
        {isTeam(member) ? (
          <SphereAvatar
            icon={<Team />}
            size="small"
            shouldHideWhiteRim={true}
            backgroundColor={sphereColors.gray700}
          />
        ) : (
          <SphereAvatar
            initials={getUserInitials(member)}
            src={member?.thumbnailUrl}
            icon={getPendingUserIcon(member, "large")}
            size="large"
            shouldUseSimplifiedAvatar={!isCurrentUser}
            shouldHideWhiteRim={true}
          />
        )}
      </Box>

      {/* Wrapper for name ane email */}
      <Box component="div">
        {/* Wrapper for name */}
        {isMemberActive(member) && (
          <Box
            component="div"
            sx={{
              fontSize: "12px",
              color: sphereColors.black,
              width: NAME_EMAIL_WRAPPER_WIDTH,
              ...withEllipsis,
            }}
          >
            <var>{getPrettyName(member)}</var>
          </Box>
        )}

        {/* Wrapper for email */}
        {!isTeam(member) && (
          <Box
            component="div"
            sx={{
              fontSize: "10px",
            }}
          >
            <var>
              {getMailToLink(member?.email ?? "", {
                color: sphereColors.blue500,
                // inline-block is needed to have ellipsis on a elements.
                display: "inline-block",
                width: NAME_EMAIL_WRAPPER_WIDTH,
                ...withEllipsis,
              })}
            </var>
          </Box>
        )}
      </Box>
    </Box>
  );
}
