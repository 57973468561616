import { useCoreApiClient } from "@api/use-core-api-client";
import { MemberTypes } from "@custom-types/member-types";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  companyMembersSelector,
  fetchingMembersFlagsSelector,
} from "@store/members/members-selector";
import { fetchCompanyMembers } from "@store/members/members-slice";
import {
  fetchingProjectsFlagsSelector,
  projectMembersSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { fetchingUserFlagsSelector } from "@store/user/user-selector";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { useEffect, useMemo } from "react";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useToast } from "@hooks/use-toast";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  TeamEvents,
  UserMenuEvents,
} from "@utils/track-event/track-event-list";
import { MemberTabs } from "@router/route-params";
import { OpenTeamDetails } from "@hooks/use-team-utils";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";

interface OpenProfilePage {
  /** The source place from where the profile is opening */
  source: string;

  /** Contains the user id or identity */
  userIdentity?: APITypes.UserIdentity | APITypes.UserId;

  /** The tab which should be selected */
  memberTab?: MemberTabs;
}

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** Whether the company members are being fetched from the backend */
  isFetchingCompanyMembers: boolean;

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** Whether the project members are being fetched from the backend */
  isFetchingProjectMembers: boolean;

  /** Callback function to open profile page of a user */
  openProfilePage(params: OpenProfilePage): void;

  /** Callback function to open team page of a user */
  openViewTeamMembers(params: OpenTeamDetails): void;
}

/**
 * Custom hook that gets the company and project members used to set the markups assignee and creator name
 *
 * For company members, only if current user has permission to view the company members list the members are returned.
 * If the user does not have permission then it returns an empty array.
 */
export function useMembersUtils(): Props {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const companyId = useAppSelector(selectedSdbCompanyIdSelector);
  const { isFetchingCurrentUser } = useAppSelector(fetchingUserFlagsSelector);
  const companyMembers = useAppSelector(companyMembersSelector);

  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const projectMembers = useAppSelector(projectMembersSelector);
  const { isFetchingProjects, isFetchingProjectContext } = useAppSelector(
    fetchingProjectsFlagsSelector
  );
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { canViewAllCompanyUsers } = useHasUserValidRoleCompanyLevel();
  const { canViewAllProjectMembers } = useHasUserValidRoleProjectLevel({
    selectedProject,
  });

  const { navigateToMemberProfile, navigateToTeamDetails } = useAppNavigation();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  /** Whether the current user has the permission to view all company members with access to the project */
  const hasPermissionToViewMembers = useMemo(() => {
    // Deny access while the current user and selected project data are being fetched
    // This data is required to check the permission
    if (
      isFetchingCurrentUser ||
      isFetchingProjects ||
      isFetchingProjectContext
    ) {
      return false;
    }

    return canViewAllProjectMembers || canViewAllCompanyUsers;
  }, [
    canViewAllCompanyUsers,
    canViewAllProjectMembers,
    isFetchingCurrentUser,
    isFetchingProjectContext,
    isFetchingProjects,
  ]);

  const members = useMemo(() => {
    return hasPermissionToViewMembers ? companyMembers : [];
  }, [companyMembers, hasPermissionToViewMembers]);

  /**
   * Fetch the company members only if the user has permissions to view the members list
   * If the company members were already fetched the members store sliced will skip the fetching
   */
  useEffect(() => {
    if (hasPermissionToViewMembers && companyId) {
      dispatch(
        fetchCompanyMembers({
          coreApiClient,
          companyId,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, hasPermissionToViewMembers]);

/**
 * Opens the team details page if the required parameters are available.
 *
 * @param {string} param0.teamId - The unique identifier of the team.
 * @param {number} [param0.numberOfMembers] - The number of members in the team (optional).
 */
  function openViewTeamMembers({
    teamId,
    numberOfMembers,
  }: OpenTeamDetails): void {
    trackEvent({
      name: TeamEvents.openTeam,
      props: {
        teamId,
        numberOfMembers: numberOfMembers ?? "",
      },
    });

    if (companyId) {
      navigateToTeamDetails({
        companyId,
        teamId,
      });
    } else {
      showToast({
        message: `${TEAM_DISPLAY_NAME} profile page cannot be opened`,
        type: "error",
        description:
          "Workspace ID is not available. Please reload the page to try again.",
      });
    }
  }

  function openProfilePage({
    source,
    userIdentity,
    memberTab,
  }: OpenProfilePage): void {
    trackEvent({
      name: UserMenuEvents.openProfilePage,
      props: { source },
    });

    if (companyId && userIdentity) {
      navigateToMemberProfile({
        companyId,
        // Encode memberId since it can be an email address and have special characters
        // That might conflict with the URL special characters
        memberId: encodeURIComponent(userIdentity),
        memberTabs: memberTab,
      });
    } else {
      showToast({
        message: "User profile page cannot be opened",
        type: "error",
        description:
          "Workspace ID and/or user ID not available. " +
          "Please reload the page to try again.",
      });
    }
  }

  return {
    companyMembers: members,
    isFetchingCompanyMembers,
    projectMembers,
    isFetchingProjectMembers: isFetchingProjects,
    openProfilePage,
    openViewTeamMembers,
  };
}
