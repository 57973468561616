import { FaroButton } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box } from "@mui/material";

interface Props {
  onClick: React.MouseEventHandler;
  /** Flag to disable the button. */
  isDisabled: boolean;
  /** The tooltip if disabled is set to true. */
  disabledTooltip?: string;
}

/** Button to import ELS scan data */
export function ImportDataButton({ onClick, isDisabled, disabledTooltip }: Props): JSX.Element {
  const tooltip = isDisabled ? disabledTooltip :
    <div>
      <div style={{ fontWeight: "bold", fontSize: "14px" }}>
        Upload from your computer
      </div>
      Currently, only .gls files can be uploaded.
    </div>;

  return (
    <SphereTooltip
      dataTestId="sa-upload-data-tooltip"
      boxProps={{
        sx: {
          display: "inline-block",
        },
      }}
      title={tooltip}
    >
      <FaroButton isDisabled={isDisabled} onClick={onClick}>
        <Box data-testid="sa-upload-data-button">
          Upload Data
        </Box>
      </FaroButton>
    </SphereTooltip>
  );
}
